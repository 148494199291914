import React from "react";
import styled from "styled-components";
import SimpleSection from "../../../components/work/simple-section";
import WorkingTogetherSection from "../../../components/work/working-together-section";
import WorkVideoElement from "../work-video-element";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import { PageElement } from "../../../graphql/page-common";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  return (
    <>
      <VideoWrapper>
        <WorkVideoElement
          video={getElementVideo(intro[0])}
          image={getElementImage(intro[0])}
          content={getElementText(intro[1])}
          padding="tablet:pr-24 tablet:mr-1"
        />
      </VideoWrapper>
      <SectionWrapper>
        <WorkingTogetherSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          image={getElementImage(content[1])}
          content={getElementText(content[2])}
          quote={{
            message:
              "We love finding innovative ways to engage with our consumers and bring the Miele brand to life in social channels.",
            name: "Eleonora",
            position: "Social Media Content Manager",
            modifier: "left-4 bottom-4 tablet:left-96 tablet:bottom-[28rem]",
            left: true,
          }}
          padding="tablet:pr-14 tablet:pb-20"
          marginBottom="tablet:mb-0"
        />
        <SimpleSection
          headline={getElementText(content[3])}
          image={getElementImage(content[4])}
          content={getElementText(content[5])}
          quote={{
            message: "Miele X is dynamic, people-driven and international – I really like the culture.",
            name: "Monny",
            position: "Senior Digital Campaign Manager",
            modifier: "left-4 bottom-4 tablet:left-auto tablet:right-[32rem] tablet:bottom-[25rem]",
          }}
          padding="tablet:pr-14"
          left
          marginTop="tablet:mt-8"
        />
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;

const Wrapper = styled.div.attrs({ className: "" })``;
const VideoWrapper = styled.div.attrs({ className: "-mt-64 tablet:-mt-72 relative z-20 scrollspy" })``;
const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;
