import React from "react";
import styled from "styled-components";
import { Headline3, Paragraph } from "../../../components/typography";
import Video from "../../../components/media/video";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import { theme } from "../../../styles/styles";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

interface ParagraphProps {
  padding?: string;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl1.current = onScrollAnimationDesktop(firstWrapper.current, [firstWrapper.current?.children || null], matchMobile);
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      [secondWrapper.current?.children || null],
      matchMobile
    );

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <SectionWrapper>
      <ExampleHeaderSection
        headline={headline}
        subHeadline={getElementText(content[0])}
        primaryImage={getElementImage(content[1])}
        video={getElementVideo(content[1])}
        content={getElementText(content[2])}
        secondaryImage={getElementImage(content[3])}
        prParagraph="4"
        marginTop="tablet:mt-7"
      />
      <Grid ref={firstWrapper}>
        <MediaCell1>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
        </MediaCell1>
        <MediaCell2>
          <VideoWrapper>
            <Video placeholder={getElementImage(content[5])} video={getElementVideo(content[5])} />
          </VideoWrapper>
        </MediaCell2>
        <MediaCell3>
          <ParagraphWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
          </ParagraphWrapper>
        </MediaCell3>
      </Grid>
      <Grid ref={secondWrapper}>
        <MediaCell4>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
        </MediaCell4>
        <MediaCell5>
          <Video placeholder={getElementImage(content[8])} video={getElementVideo(content[8])} />
        </MediaCell5>
        <MediaCell6>
          <ParagraphWrapper padding="tablet:pr-4">
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
          </ParagraphWrapper>
        </MediaCell6>
      </Grid>
    </SectionWrapper>
  );
};

export default ExamplesSection;

const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const Grid = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12 mb-12 tablet:mb-32",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding ? padding : "tablet:pr-14"}`,
}))<ParagraphProps>``;
const VideoWrapper = styled.div.attrs({
  className: "tablet:w-10/12 tablet:ml-auto tablet:mr-auto mb-8 tablet:mb-0",
})``;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 tablet:pt-8",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-6 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 tablet:mt-12",
})``;
const MediaCell5 = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-3 tablet:w-11/12 tablet:ml-auto mb-8 tablet:mb-0",
})``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1",
})``;
